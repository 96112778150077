<template>
    <div>
            <section class="section-coming-soon section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/hormonal-imbalance.jpg') + ')'}">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-12">
                                <h1 class="display-1  text-white">Bloating and Digestive Issues
                                    <!-- <span>When water builds up in the body, it can cause bloating and puffiness, especially in the abdomen, legs, and arms.</span> -->
                                </h1>
                                <p class="lead  text-white">A how to guide to manage bloating and improve your digestion.<br>
                                When water builds up in the body, it can cause bloating and puffiness, especially in the abdomen, legs, and arms. <br>
                                Water levels can make a person's weight fluctuate by as much as 2 to 4 pounds in a single day. Severe water retention can be a symptom of heart or kidney disease.<br><br>
                                This course is coming soon..</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                         href="/contact"
                                         type="black"
                                         icon="ni ni-user-run"
                                         class="mb-3 mb-sm-0 btn-dark">
                                Pre-register Today
                            </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>

<script>
export default {
  name: 'Bwr',
  metaInfo: {
    title: 'Bloating and Water Retention'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
