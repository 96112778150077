<template>
    <div>
            <section class="section-coming-soon section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/nutrient-rich-foods.jpg') + ')'}">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-12">
                                <h1 class="display-1 text-white">Nutrient-Rich Foods Guide</h1>
                                <p class="lead  text-white">Eating a diet rich in essential nutrients is crucial for maintaining overall health and well-being. In the detailed PDF guide available for download below, you'll find comprehensive information about various foods that are packed with vital nutrients, along with tips on how to incorporate them into your daily meals.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                         href="/nutrient-rich-foods.pdf"
                                                 download
                                         type="warning"
                                         icon="ni ni-cloud-download-95"
                                         class="mb-3 mb-sm-0 shadow">
                                      Download Nutrient-Rich Foods Guide
                            </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>

<script>

export default {
  name: 'nutrient-rich-foods',
  metaInfo: {
    title: 'Nutrient Rich Foods Guide'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
