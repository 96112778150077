<template>
<div>
<section class="section-pms section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/period-pain.jpg') + ')'}">
        <div class="shape shape-style-1 shape-primary">
            <span class="span-150">you</span>
            <span class="span-50"></span>
            <span class="span-50"></span>
            <span class="span-75"></span>
            <span class="span-100"></span>
            <span class="span-75"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
        </div>
        <div class="container shape-container d-flex align-items-center">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-10 text-center">
                        <h1 class="display-3 text-white">A Self-Help Course to Treat PMS, Period Pain and Hormonal Imbalance with Natural Medicine</h1>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 text-center pt-lg">
                        <h4 class="text-white mt-4 mb-5"></h4>
                        <div class="btn-wrapper">
                            <base-button tag="a"
                            type="black"
                                         href="https://naturalmedicineportal.teachable.com/p/pms/"
                                         class="mb-3 mb-sm-0 btn-dark"
                                         icon="ni ni-user-run">
                                FIND OUT MORE
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<section class="section section-lg">
      <div class="container">
        <card class="border-0">
          <div class="row row-grid align-items-center">
            <div class="col-lg-12 text-center p-lg-5">
              <div class="mb-5 mb-lg-0">

                <h2 class="text-black">
                  Treating PMS, Period Pain and Hormonal Imbalance with Natural Medicine
                </h2>
<p class="mt-4">
For many women, PMS and mild or intense pain is just a normal monthly occurrence accompanying their periods…<br>
Sources claim PreMenstrual Symptoms, or PMS, to a certain degree, affects up to 90 percent of women but that doesn’t mean it’s healthy or should be considered the norm and this course will help you understand how to treat yourself to alleviate this in the comfort of your own home. <br><br>

Optimal health is mirrored as a painless, regular, moderate flow with no noticeable symptoms. <br>
The menstrual blood should be similar to the blood that would flow if you cut yourself – fresh and red with no clots. <br>
This is what should be considered a normal flow. <br><br>

In fact, in Natural Medicine, PMS is an alarm bell for imbalances in the body. <br>
Natural medicine practitioners will use the menses (mucosal tissue that is shed during menstruation) as an indicator for a woman’s overall health.
                </p>
                                <a
                  href="https://naturalmedicineportal.teachable.com/p/pms/"
                  class="btn btn-dark mt-4"
                  >Find out more..
                  </a><br><br>
                <h5>
                  During the Flowing Womban course
                </h5>
<p class="mt-4">
Holistic healthcare practitioner and clinical acupuncturist Michelle Hansen decodes the mysteries shrouding premenstrual pain and symptoms.<br>
You will learn what PMS is, why women experience it and how to heal with the Natural and Chinese Medicine way. <br>
This uses natural methods such as appropriate exercise, proper nutrition, moxibustion, acupuncture points and more. <br>
The course also dives into the fundamental philosophies of Natural and Chinese Medicine and why this ancient and sophisticated healthcare module is so effective when it comes to PMS treatment.
</p>
                <h5>
                  What to expect..
                </h5>

<p class="mt-4">
During the course you will<br>
Explore the menstrual cycle and how the female body works <br>
Learn about Qi, Yin and Yang, The Five Elements of Chinese Medicine and other key Natural and Chinese Medicine principles <br>
Gain a deeper understanding of the different organs in the body, which play a role in the development PMS <br>
Learn how to identify and treat the root causes of PMS using safe, simple Natural and Chinese Medicine practices <br>
Be provided with the necessary tools to live more healthily as a whole<br>
Become informed, empowered and confident enough to self-heal
</p>
                <a
                  href="https://naturalmedicineportal.teachable.com/p/pms/"
                  class="btn btn-dark mt-4"
                  >Sign Up for the Course Today<br>
                                One payment of US$169 or 3 monthly payments
                  </a><br><br>

                <!-- <h5>The Course Outline</h5> -->

<!-- <p class="mt-4">
1. Introduction: Why the course exists, what is PMS, and understanding Natural and Chinese Medicine and their fundamental principles and their application.<br>
2 .The Patterns of Natural and Chinese Medicine: Modules focusing on the liver, digestive system, and kidneys and how they are connected to the menstrual cycle<br>
3 .Your Body and Your Cycle: An in-depth personal look at your nervous system, hormones, health history, and other factors that can have an impact on the four phases of your cycle.<br>
4 .Treatment:  Naturopathic detoxification concepts that apply to menstruation, and a variety of treatment strategies that can be self-implemented at home. <br>
This section is an extensive toolkit of Natural and Chinese Medicine, how to use moxa sticks, and the treatment of the liver, hormones, kidneys, blood deficiency, and treating and preventing menstrual pain. <br><br>

There's no deadlines or time limits – study at your own pace. Whether you choose a module a week or a module a day, the choice and the knowledge is yours.<br><br>

At the end, you’ll have a greater understanding of PMS, period pain and hormonal imbalance, and how to not only treat the pain, but prevent it from happening.  </p> -->
                                <!-- <a
                  href="https://naturalmedicineportal.teachable.com/p/pms/"
                  class="btn btn-dark mt-4"
                  >FIND OUT MORE
                  </a> -->

              </div>
            </div>

          </div>
          <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <div class="card bg-default shadow border-0">
                            <img v-lazy="$options.filters.imgsrc('img/michele.jpeg')" alt="PMS Hormonal Imbalance" class="card-img-top">
                            <!-- <blockquote class="card-blockquote">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                                     class="svg-bg">
                                    <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                                </svg>
                                <h4 class="display-3 font-weight-bold text-white">Design System</h4>
                                <p class="lead text-italic text-white">The Arctic Ocean freezes every winter and much of
                                    the sea-ice then thaws every summer, and that process will continue whatever
                                    happens.</p>
                            </blockquote> -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">

                            <h3>This course is for every menstruating woman.</h3>
                            <p class="lead">“I am yet to meet a woman in these modern times who does not experience some expression of hormonal imbalance from time to time: whether that's premenstrual tension, ovarian cysts, moodiness, acne, bloating, weight gain or irregular periods.
By understanding the predictable rhythmic energy, or Qi, movements within a woman's body, we can own our cycle with full awareness. Then we can adjust our daily activities, apply treatments, and in doing so, rebalance our hormones, waving goodbye to PMS and period pain for good.”</p>
<p class="text-center">
- Michelle Hansen
</p>
                            <!-- <a href="#" class="font-weight-bold text-warning mt-5">A beautiful UI Kit for impactful
                                websites</a> -->
                        </div>
                    </div>
                </div>
                <div class="row row-grid align-items-center">
                  <div class="col-md-12">

                  <p class="mt-4">

The Flowing Womban course will create happier, healthier periods. It's divided into four easy-to-follow sections with a total of 21 modules containing written information and guidelines, diagrams and demonstration videos.
</p>
                  </div>
                </div>

                <div class="row text-center justify-content-center">
                    <div class="col-lg-10">
                        <h2 class="display-3">The Course Outline</h2>
                    </div>
                </div>
                <div class="row row-grid mt-0">
                    <div class="col-lg-6">
                      <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-settings" gradient="success" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">Introduction</h5>
                                    <p>Why the course exists, what is PMS, and understanding Natural and Chinese Medicine and their fundamental principles and their application.</p>
                                    <!-- <a href="#" class="text-success">Learn more</a> -->
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-lg-6">
                      <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-ruler-pencil" gradient="success" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">The Patterns of Natural and Chinese Medicine</h5>
                                    <p>Modules focusing on the liver, digestive system, and kidneys and how they are connected to the menstrual cycle.</p>
                                    <!-- <a href="#" class="text-success">Learn more</a> -->
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-lg-6">
                      <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-planet" gradient="success" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">Your Body and Your Cycle</h5>
                                    <p>
                                      An in-depth personal look at your nervous system, hormones, health history, and other factors that can have an impact on the four phases of your cycle.
                                    </p>
                                    <!-- <a href="#" class="text-success">Learn more</a> -->
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="col-lg-6">
                      <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-satisfied" gradient="success" color="white" shadow
                                          rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">Treatment</h5>
                                    <p>
                                      Naturopathic detoxification concepts that apply to menstruation, and a variety of treatment strategies that can be self-implemented at home.
                                    </p>
                                    <!-- <a href="#" class="text-success">Learn more</a> -->
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
                <hr>
                <div class="row text-center justify-content-center">
                  <p class="lead">The treatment section provides an extensive tool kit of natural and Chinese medicine including how to use moxa sticks and the treatment of the liver, hormones, kidneys, blood deficiency, and treating and preventing menstrual pain. <br><br>

There's no deadlines or time limits – study at your own pace. Whether you choose a module a week or a module a day, the choice and the knowledge is yours.<br><br>

At the end, you’ll have a greater understanding of PMS, period pain and hormonal imbalance, and how to not only treat the pain, but prevent it from happening.</p>
                <a
                  href="https://naturalmedicineportal.teachable.com/p/pms/"
                  class="btn btn-dark mt-4"
                  target="_blank"
                  >Sign Up For the Course Today
                  </a>
                </div>
        </card>
      </div>
    </section>

    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0 m-0 p-0" style="height : 250px !important">

        </section>
        <section class="section section-skew">
            <div class="container">
              <LazyHydrate when-visible>
              <Practitioner></Practitioner>
              </LazyHydrate>
            </div>
        </section>
    </div>
    <LazyHydrate when-visible>
      <Testimonials></Testimonials>
    </LazyHydrate>

  </div>
</template>
<script>
import LazyHydrate from 'vue-lazy-hydration'
export default {
  name: 'PMS',
  components: {
    LazyHydrate,
    Practitioner: () => import('../components/Practitioner.vue'),
    Testimonials: () => import('../components/Testimonials.vue')
  },
  metaInfo: {
    title: 'PMS Hormonal Imbalance / Period Pain Relief',
    // override the parent template and just use the above title only
    titleTemplate: null
  }
}
</script>
<style>
</style>
