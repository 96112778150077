<template>
    <div>
            <section class="section-coming-soon section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/forms.jpg') + ')'}">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center text-white">
                            <div class="col-lg-6">
                                <h3 class="display-3 text-white">Click on the right form for you</h3>
                                <div class="btn-wrapper">
                                  <!-- <base-button tag="a"
                                         href="https://bluebuddhabali.forms-db.com/view.php?id=11609"
                                         type="black"
                                         target="_blank"
                                         class="mb-3 mb-sm-0 btn-dark w-75">
                                WOMANS HEALTH - NEW PATIENT QUESTIONNAIRE
                            </base-button> -->
                            <router-link
          to="/forms/new-patient-women"
          class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
        >
          WOMANS HEALTH - NEW PATIENT QUESTIONNAIRE
        </router-link>
                            <br>
                            <br>
                                  <router-link
          to="/forms/new-patient-men"
          class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
        >
          MENS HEALTH - NEW PATIENT QUESTIONNAIRE
        </router-link>
                                  <!-- <base-button tag="a"
                                         href="https://bluebuddhabali.forms-db.com/view.php?id=10399"
                                         type="black"
                                         target="_blank"
                                         class="mb-3 mb-sm-0 btn-dark w-75">
                                MENS HEALTH - NEW PATIENT QUESTIONNAIRE
                            </base-button> -->
                            <br>
                            <br>
                            <router-link
          to="/forms/new-patient-musculoskeletal"
          class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
        >
           MUSCULOSKELETAL / INJURY QUESTIONNAIRE
        </router-link>
                                  <!-- <base-button tag="a"
                                         href="https://bluebuddhabali.forms-db.com/view.php?id=12099"
                                         type="black"
                                         target="_blank"
                                         class="mb-3 mb-sm-0 btn-dark w-75">
                                MUSCULOSKELETAL / INJURY QUESTIONNAIRE
                            </base-button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>
<script>

export default {
  name: 'forms',
  metaInfo: {
    title: 'Download Forms'
  },
  data () {
    return {
      posts: []
    }
  }
}
</script>
<style scoped>
/* .blog-post-top-img{
  min-height: 700px;
} */
</style>
