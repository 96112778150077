<template>
    <div>
            <section class="section-coming-soon section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/menopause.jpg') + ')'}">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-lg-12">
                                <h1 class="display-1  text-white">Perimenopause & Menopause
                                </h1>
                                <p class="lead  text-white">
Most women are very ill informed about this life changing transition process.<br>
For example, did you know perimenopause often starts up to ten years before actual menopause. <br>
If you understand the body's process of change and apply the natural remedies provided in this course much of the discomfort many experience with menopause can easily be avoided and if you are already in the grips of menopause symptoms, don't fret, I will show you a combination of natural methods to end your suffering once and for all...<br><br>
This course is coming soon.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                         href="/contact"
                                         type="black"
                                         icon="ni ni-user-run"
                                         class="mb-3 mb-sm-0 btn-dark">
                                Pre-register Today
                            </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
</template>

<script>

export default {
  name: 'Menopause',
  metaInfo: {
    title: 'Menopause'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
