<template>
  <div>
    <section class="section-natural-medicine section-shaped my-0"
             :style="{'background-image': 'url(' + $options.filters.imgsrc('img/online-consultation-food.jpg') + ')'}">
      <div class="shape shape-style-1 shape-warning bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container shape-container d-flex align-items-center text-center">
        <div class="col px-0">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-8">
              <h1 class="display-1 text-white">ONLINE CONSULTATION</h1>
<!--              <p class="lead  text-white">Natural medicine is a system that uses natural-->
<!--                remedies to help the body heal from disease.</p>-->

<!--              <div class="btn-wrapper">-->
                <!-- <base-button tag="a" href="/" type="black" icon="ni ni-user-run"
                  class="mb-3 mb-sm-0 btn-dark">
                  Remove button
                </base-button> -->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section my-5 p-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-12">
            <h2 class="display-3 text-white">Consultation Information</h2>

            <p class="lead text-left text-white">Before our consultation, you'll receive a crucial online form to complete. This form is not just a formality, but a tool that helps me gather essential information about your health history, current symptoms, and lifestyle habits. It's the foundation of our productive session. Additionally, I'll request a photo of your tongue, a key element in traditional Chinese medicine diagnosis. This photo is not just a picture, but a valuable resource that assists me in assessing your overall health and identifying any potential imbalances.</p>

            <p class="lead text-left text-white">In preparation for our session, I also ask that you provide any current relevant pathology tests. These tests offer valuable insights into your health status and can help guide our discussions and treatment strategies.</p>

            <p class="lead text-left text-white">During our online consultation, you'll benefit from over 30 years of clinical experience as a Traditional Chinese Medical Practitioner, Acupuncturist, Chinese Herbalist and Natural Therapist. I offer an integrative approach to health and wellness, drawing from Traditional Chinese Medicine (TCM) and natural therapies. I aim to provide personalized assessments, tailoring our approach to your unique health needs and goals, utilizing a blend of traditional and modern diagnostic methods.</p>

            <p class="lead text-left text-white">In addition to TCM diagnostics such as tongue diagnosis, observational diagnostics and pattern recognition derived from detailed history taking and questioning, I also employ modern assessments, including pathology and medical history. This comprehensive approach allows a thorough understanding of your health status, combining ancient wisdom with contemporary medical knowledge.</p>

            <p class="lead text-left text-white">I will recommend Evidence-based supplements alongside traditional Chinese herbal prescriptions as part of your treatment plan. These supplements are selected based on scientific research and intended to complement the benefits of Chinese herbs, enhancing your body's natural healing processes.</p>

            <p class="lead text-left text-white">Further, we will discuss dietary and lifestyle approaches to healing. Education is a crucial component of the healing process; I'll provide insights into your health condition and empower you with knowledge about your body and its natural rhythms. Together, we'll explore dietary adjustments and lifestyle modifications that support your overall well-being and contribute to your healing journey.</p>

          </div>
        </div>
        <div class="row row-grid justify-content-center">
          <div class="col-md-4 py-5">
            <card class="border-0" hover shadow body-classes="py-5">
              <h4 class="text-warning text-uppercase">Single Consultation Package</h4>
              <p class="display-4"><strong>Duration:</strong><span class="font-italic appointment">60-90 minutes (as required)</span></p>
              <p class="display-4"><strong>Fee:</strong> <span class="text-warning fee">USD 333</span></p>

              <p class="display-4"><strong>Includes:</strong></p>
              <ul class="text-left">
                <li><strong>Initial Consultation:</strong> <br> Comprehensive assessment tailored to your needs.</li>
                <li><strong>Personalized Supplement Prescription:</strong> Evidence-based dosing and selection of the most effective compounds for your specific requirements.</li>
                <li><strong>Chinese Herbal Medicine Prescription:</strong> I will personally source and order TCM herbs for you, as they may be unavailable to the general public in many countries. (Please note: Herbal medicines are at an additional cost.)</li>
                <li><strong>Lifestyle & Dietary Prescriptions:</strong> Customized recommendations to support your healing journey.</li>
                <li><strong>Guidance on Acupressure and Moxibustion:</strong> Gain insight into the acupoints tailored to your needs and learn how to apply Moxibustion, where applicable, to enhance healing.</li>
                <li><strong>Support:</strong> Assistance in addressing emotional blocks that may hinder your healing process.</li>
                <li><strong>Three Days of WhatsApp or Email Support:</strong> Reach out with your initial questions or concerns regarding the consultation.</li>
              </ul>
            </card>
          </div>
          <div class="col-md-4 py-5 floating">
            <card class="border-0 relative" hover shadow body-classes="py-5">
              <div data-aos="flip-left"
                   data-aos-easing="ease-out-cubic"
                   data-aos-duration="2000" style="position: absolute; top: -20px; left: 50%; transform: translateX(-50%); padding: 5px 10px; border-radius: 5px; font-weight: bold;">
                <badge type="danger" rounded>Most Popular</badge>
              </div>
              <h4 class="text-danger text-uppercase">Initial Consultation + Three-Month Portal to Wellness (Extended Support)</h4>
              <p class="display-4"><strong>Duration:</strong> <span class="font-italic appointment">3 months, Appointments every two weeks</span></p>
              <p class="display-4"><strong>Fee:</strong> <span class="text-danger fee">USD 2999</span></p>
              <p class="display-4"><strong>Includes:</strong></p>
              <ul class="text-left">
                <li>
                  <strong>Initial Consultation:</strong><br>
                  Comprehensive assessment tailored to your needs as per Single Consult.<br>
                  <strong>Duration:</strong> 60-90 minutes (as required)
                </li>
                <li>
                  <strong>Four additional Follow-up Consultations approx. Every 2 Weeks:</strong><br>
                  Ensuring Regular check-ins to monitor progress and adjust treatment plans as needed, providing a continuous support system to guide you towards your wellness goals. 12 consults in total.
                </li>
                <li>
                  <strong>PLUS:</strong> Unlimited WhatsApp and Email Support During the 3 Months:<br>
                  Get prompt support and guidance whenever you need it throughout your wellness journey.
                </li>
                <li>
                  <strong>PLUS:</strong> In addition to ordering the TCM herbs for you, I will provide 100% assistance in sourcing practitioner-only quality supplements where possible, ensuring you receive the highest standard of support.
                </li>
                <li>
                  <strong>PLUS:</strong> My Online Course FLOWING WOMBAN: A GUIDE TO HORMONAL BALANCE FOR FREE
                </li>
                <li>
                  <strong>PLUS:</strong> INFORMATIVE HANDOUTS and written instructions were REQUIRED.
                </li>
              </ul>

              <p>The Three month Wellness Portal delivers lasting benefits by providing consistent accountability, personalized support, and access to updated resources. It ensures sustained progress towards your goals, fostering positive habits and routines. With expert guidance and tailored advice, it maximizes your investment in personal growth, empowering you for long-term success and well-being.</p>

            </card>
          </div>
          <div class="col-md-4 py-5">
            <card class="border-0" hover shadow body-classes="py-5">
              <h4 class="text-warning text-uppercase"> INITIAL CONSULTATION + SIX-MONTH PORTAL TO WELLNESS (VIP SUPPORT)</h4>
              <p class="display-4"><strong>Duration:</strong> <span class="font-italic appointment">Weekly appointments are 30-45 min as required</span></p>
              <p class="display-4"><strong>Fee:</strong> <span class="text-warning fee">USD 5999</span></p>
              <p class="display-4"><strong>Includes:</strong></p>
              <p class="text-left">Initial appointment 60-90 min as required as per Single Consult</p>
              <p class="text-left">Building upon the foundation of our Three Month package, the Six Month Portal to Healing offers an unparalleled level of support. Your dedicated and personalized TCM doc and Wellness coach are just a call away, ready to offer guidance and encouragement whenever needed.</p>
              <p class="text-left">With six months of dedicated support, I am here to hold your hand through your healing journey when and however you need it—this is the ultimate healing package.</p>
            </card>
          </div>
        </div>
      </div>
    </section>
    <!-- Testimonials -->
    <LazyHydrate when-visible>
      <ConsultTestimonials></ConsultTestimonials>
    </LazyHydrate>
    <!-- End testimonials -->
  </div>
</template>
<style scoped>
.blog-post-top-heading{
  letter-spacing: .1em;
  line-height: 1.5em;
}
.card {
  background-color: #fff !important;
  border-radius: 10px !important;
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  //transition: box-shadow 0.3s ease !important;
}

.card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2) !important;
}

.card-body {
  padding: 20px !important;
}

.card p {
  margin-bottom: 10px !important;
  font-size: 1rem !important;
}

.card ul {
  list-style: none !important;
  padding: 0 !important;
}

.card ul li {
  margin-bottom: 10px !important;
}

/* Example of adding background colors to alternate cards */
.card:nth-child(even) {
  background-color: #f8f9fa !important;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure all items stretch to fill the container */
  height: 100%; /* Ensure the card-body takes up the full height */
}

h6 {
  margin-top: 0; /* Reset margin */
  margin-bottom: 0; /* Reset margin */
  height: 60px; /* Set a fixed height for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
}
.appointment{
  font-size: 16px;
  line-height: 1.3em;
  font-weight: 700;
  padding:10px;
}
.fee{
  font-size: 16px;
  line-height: 1.3em;
  font-weight: 700;
  padding:10px;
}
</style>
<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'online-consultation',
  components: {
    LazyHydrate,
    ConsultTestimonials: () => import('./components/ConsultTestimonials.vue')
  },
  metaInfo: {
    title: 'Online Consultation'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
