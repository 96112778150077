<template>
  <div>
    <section class="section-home section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/home-banner.jpg') + ')'}">
        <div class="shape shape-style-1 shape-primary">
            <span class="span-150">you</span>
            <span class="span-50"></span>
            <span class="span-50"></span>
            <span class="span-75"></span>
            <span class="span-100"></span>
            <span class="span-75"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
        </div>
      <div class="container shape-container d-flex align-items-center">
        <div class="col px-0">
          <div class="row justify-content-center align-items-center text-center">
            <div class="col-lg-12">
              <h1 class="display-1 text-white">Natural Medicine Portal</h1>
              <p class="lead text-white">
                Your one stop portal to health and well-being</p>
                <div class="btn-wrapper">
                    <!-- <base-button tag="a"
                    type="black"
                                  href="#"
                                  class="mb-3 mb-sm-0 btn-dark"
                                  icon="ni ni-chat-round">
                        ZOOM CONSULTS
                    </base-button> -->
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->

    <!-- :src="'img/home-banner.mp4' | imgsrc" -->
    <!-- https://player.vimeo.com/external/329797719.hd.mp4?s=54d13f3ce0327bb568dbba7bd0fa6c078828730b&profile_id=174 -->
    <!-- overlay="linear-gradient(45deg,#05150ea3,#0000006b)" -->
    <!-- <video-background
    :src="'https://player.vimeo.com/external/329797719.hd.mp4?s=54d13f3ce0327bb568dbba7bd0fa6c078828730b&profile_id=174'"
      style="max-height: 1040px; height: 90vh"
      overlay="linear-gradient(to bottom,#000000,#00000000)"
      class="section-hero section-shaped my-0"
    > -->
      <!-- <div class="container shape-container d-flex align-items-center h-100">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 text-center pt-lg">
                        <img :src="'img/brand/logo-white.png' | imgsrc" style="width: 200px;" class="img-fluid">
                        <p class="lead text-white mt-4 mb-5">Let’s find out how we can help you to achieve your best health.</p>
                        <div class="btn-wrapper">
                            <base-button tag="a"
                                         href="https://demos.creative-tim.com/vue-argon-design-system/documentation"
                                         class="mb-3 mb-sm-0"
                                         type="info"
                                         icon="fa fa-envelope">
                                Get in touch
                            </base-button>
                             <base-button tag="a"
                                         href="https://www.creative-tim.com/product/vue-argon-design-system"
                                         class="mb-3 mb-sm-0"
                                         type="white"
                                         icon="ni ni-cloud-download-95">
                                Download Vue
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- </video-background> -->
    <!-- HERO -->
    <!-- <section class="section-hero section-shaped my-0">
        <div class="shape shape-style-1 shape-primary">
            <span class="span-150">you</span>
            <span class="span-50"></span>
            <span class="span-50"></span>
            <span class="span-75"></span>
            <span class="span-100"></span>
            <span class="span-75"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
        </div>
        <div class="container shape-container d-flex align-items-center">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 text-center pt-lg">
                        <img src="img/brand/white.png" style="width: 200px;" class="img-fluid">
                        <p class="lead text-white mt-4 mb-5">A beautiful Design System for Bootstrap 4. It's Free and Open Source.</p>
                        <div class="btn-wrapper">
                            <base-button tag="a"
                                         href="https://demos.creative-tim.com/vue-argon-design-system/documentation"
                                         class="mb-3 mb-sm-0"
                                         type="info"
                                         icon="fa fa-code">
                                Components
                            </base-button>
                            <base-button tag="a"
                                         href="https://www.creative-tim.com/product/vue-argon-design-system"
                                         class="mb-3 mb-sm-0"
                                         type="white"
                                         icon="ni ni-cloud-download-95">
                                Download Vue
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- END HERO -->
    <section class="section my-5 p-0">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <p class="lead text-uppercase text-white">Treatments and Options</p>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-3">
            <router-link to="/course/pms_and_period_pain_relief">
            <card class="border-0 cursor-pointer" shadow body-classes="p-0 text-center">
                <img
                  v-lazy="
                    $options.filters.imgsrc('img/courses.jpg')
                  "
                  class="card-img-top"
                  alt="PMS Hormonal Imbalance"
                />
                <h6 class="text-dark text-uppercase mt-2">Courses</h6>
                <p class="description mt-3">PMS, Peroid Pain and Hormonal Imbalance</p>
            </card>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link to="/acupuncture">
            <card class="border-0 cursor-pointer" shadow body-classes="p-0 text-center">
                <img
                  v-lazy="
                    $options.filters.imgsrc('img/accupuncture.jpg')
                  "
                  alt="PMS Hormonal Imbalance"
                  class="card-img-top"
                />
                <h6 class="text-dark text-uppercase mt-2">Acupuncture</h6>
                <p class="description mt-3">Traditional Chinese Acupuncture and Moxibustion or Gentle and painless Traditional Japanese Acupuncture</p>
            </card>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link to="/natural-medicine">
            <card class="border-0 cursor-pointer" shadow body-classes="p-0 text-center">
                <img
                  v-lazy="
                    $options.filters.imgsrc('img/natural-medicine.jpg')
                  "
                  alt="PMS Hormonal Imbalance"
                  class="card-img-top"
                />
                <h6 class="text-dark text-uppercase mt-2">Natural Medicine</h6>
                <p class="description mt-3">Herbal formula prescriptions - Ancient wisdom with a modern twist for your convenience</p>
            </card>
            </router-link>
          </div>
          <div class="col-md-3">
            <router-link to="/consults">
            <card class="border-0 cursor-pointer" shadow body-classes="p-0 text-center">
                <img
                  v-lazy="
                    $options.filters.imgsrc('img/consults.jpg')
                  "
                  class="card-img-top"
                  alt="PMS Hormonal Imbalance"
                />
                <h6 class="text-dark text-uppercase mt-2">Consults</h6>
                <p class="description mt-3">Let’s find out how we can help you to achieve your best health, book a consult at the clinic or over Zoom</p>
            </card>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="section section section-shaped p-0 my-0 overflow-hidden">
      <div class="shape bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">

          <div class="col-lg-6 text-center">
            <div class="mb-5 mb-lg-0 m-5">
              <h4 class="text-white">
                BALI BASED ACUPUNCTURE, NATURAL MEDICINE & HEALING PORTAL
              </h4>
              <h3 class="text-white mt-4">
                We work in partnership with you to get to the root of your health care problems, not merely treat your symptoms. <br>
                Only with a true holistic approach can real healing take place.
              </h3>
              <base-button
                data-aos="zoom-in"
                tag="a"
                                         href="/contact"
                                         type="black"
                                         class="mb-3 mb-sm-0 mt-4 btn-dark">
                                Contact Us
                            </base-button>

            </div>
          </div>
          <div class="col-md-6 ml-lg-auto m-auto">
            <div class="position-relative pl-md-5">
              <img
              v-lazy="$options.filters.imgsrc('img/bali-based-section.jpeg')"
                class="img-center img-fluid w-75"
                alt="PMS Hormonal Imbalance"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" data-aos="zoom-in">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h2 text-white class="display-3">How we can help you..</h2>
            <!-- <p class="lead">According to the National Oceanic and Atmospheric Administration,
                            Ted, Scambos, NSIDClead scentist, puts the potentially record low maximum sea ice extent
                            tihs year down to low ice.</p> -->
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/pregnant-women.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                  Women's Hormonal Issues
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/sleep-disorder.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                  Mood and Sleep Disorders
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/headache.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                Headaches, Migraines and Pain</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-grid align-items-center">
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/fatigue.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                Fatigue</h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/internal-medicine.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                  DIGESTIVE ISSUES
                </h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-dark">
              <img
                class="card-img opacity-5"
                v-lazy="$options.filters.imgsrc('img/muscle-pain.jpg')"
                alt="PMS Hormonal Imbalance"
              />
              <div
                class="card-img-overlay align-items-center d-flex justify-content-center"
              >
                <h3 class="card-title text-white text-uppercase text-center" data-aos="zoom-in">
                  Injury, Accident, Musculoskeletal Pain and Recovery
                </h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- Testimonials -->
    <LazyHydrate when-visible>
      <Testimonials></Testimonials>
    </LazyHydrate>
    <!-- End testimonials -->
    <section class="section section section-shaped overflow-hidden" >
      <div class="shape bg-gradient-warning">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-12 text-center">
            <div class="mb-5 mb-lg-0">
              <h2>
                Schedule your appointment online or email us
              </h2>
              <p class="mt-4">
                We are available for in-clinic and virtual appointments five days per week.<br>
Appointments times are available from 7.30 am to 4pm Bali time.<br>
The clinic is located on the popular Batu Bolong street, Canggu, 100m from the beach.
              </p>

                <base-button
                data-aos="zoom-in"
                tag="a"
                                         href="/contact"
                                         type="black"
                                         icon="ni ni-calendar-grid-58"
                                         class="mb-3 mb-sm-0 mt-4 btn-dark">
                                Book An appointment
                            </base-button>
                <base-button
                data-aos="zoom-in"
                tag="a"
                                         href="#"
                                         type="black"
                                         icon="ni ni-chat-round"
                                         class="mb-3 mb-sm-0 mt-4 btn-dark">
                                ZOOM CONSULTS
                            </base-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <carousel></carousel> -->
    <!-- <custom-controls></custom-controls> -->
    <!-- <navigation></navigation> -->
    <!-- <javascript-components></javascript-components> -->
    <!-- <icons></icons> -->
    <!-- <examples></examples> -->
    <!-- <download-section></download-section> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Hero from './components/Hero'
// import BasicElements from './components/BasicElements'
// import Inputs from './components/Inputs'
// import CustomControls from './components/CustomControls'
// import Navigation from './components/Navigation'
// import JavascriptComponents from './components/JavascriptComponents'
// import Carousel from './components/Carousel'
// import Icons from './components/Icons'
// import Examples from './components/Examples'
// import DownloadSection from './components/DownloadSection'

// import Testimonials from './components/Testimonials.vue'
// import { BCarousel } from 'bootstrap-vue/esm/components/carousel/carousel'
// import { BCarouselSlide } from 'bootstrap-vue/esm/components/carousel/carousel-slide'
// import VideoBackground from 'vue-responsive-video-background-player'
// Vue.component('video-background', VideoBackground)
import LazyHydrate from 'vue-lazy-hydration'
export default {
  name: 'Home',
  components: {
    LazyHydrate,
    Testimonials: () => import('./components/Testimonials.vue')
    // HelloWorld,
    // VideoBackground,
    // Hero,
    // BasicElements,
    // Inputs,
    // CustomControls,
    // Navigation,
    // JavascriptComponents,
    // Carousel,
    // BCarousel,
    // BCarouselSlide
    // Icons,
    // Examples,
    // DownloadSection
  },
  metaInfo: {
    title: 'Natural Medicine Portal',
    // override the parent template and just use the above title only
    titleTemplate: null
  }
}
</script>
