<template>
    <div>
            <section class="section-coming-soon section-shaped my-0" :style="{'background-image': 'url(' + $options.filters.imgsrc('img/consults.jpg') + ')'}">
                <div class="shape shape-style-1 shape-warning bg-gradient-warning">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex align-items-center text-center">
                    <div class="col px-0">
                        <div class="row justify-content-center align-items-center text-white">
                            <div class="col-lg-10 pt-lg pt-md pt-sm pt-xs">
                                <h1 class="display-1  text-white">Bali Clinic Appointments</h1>
                                <p class="lead mt-0 text-white">a typical consult involves...<br>
Chinese Medicine diagnosis, acupuncture treatment, herbal and naturopathic prescriptions and Lifestyle medicine (including dietary advice)<br><br> </p>

You will need to fill an extensive online form before the appointment, allowing us to make the most of our time together.
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                         href="/contact"
                                         type="black"
                                         icon="ni ni-user-run"
                                         class="mb-3 mb-sm-0 btn-dark w-25">
                                Book a Consultation
                            </base-button>
                            <br>
                            <br>
                                    <base-button tag="a"
                                         href="/forms"
                                         type="black"
                                         icon="ni ni-single-copy-04"
                                         class="mb-3 mb-sm-0 btn-dark w-25">
                                Download Forms
                            </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      <section class="section">
        <div class="container text-white">
          <div class="max-w-4xl mx-auto px-4 py-8">
            <!-- Initial Consultation Section -->
            <section class="mb-8">
              <h2 class="display-3 text-white mb-4">Initial Consultation (60-75 minutes)</h2>
              <p class="mb-4">During our initial consultation, we'll discuss your wellness goals, health concerns, and any specific issues you're experiencing. Through the lens of Traditional Chinese Medicine (TCM), I'll conduct a comprehensive diagnosis to understand the root causes of your symptoms.</p>
              <p class="mb-4">Based on this diagnosis, we proceed with acupuncture treatment. Additionally, we might incorporate moxibustion therapy if it benefits your condition.</p>
              <p class="mb-4">Following the session, I'll provide tailored Chinese Herbal and naturopathic prescriptions to support your healing. Moreover, we'll discuss important dietary and lifestyle considerations to optimize your overall well-being and promote healing.</p>
            </section>
            <!-- Pre-Appointment Section -->
            <section class="mb-8">
              <h2 class="display-3 text-white mb-4">Pre-Appointment</h2>
              <ol class="list-decimal mb-4">
                <li class="mb-2">Please complete an online form using the link below before your initial appointment.</li>
                <div class="col-lg-6">
                <div class="align-items-center">

                    <router-link
                      to="/forms/new-patient-women"
                      class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
                    >
                      WOMANS HEALTH - NEW PATIENT QUESTIONNAIRE
                    </router-link>
                  <br><br>
                    <router-link
                      to="/forms/new-patient-men"
                      class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
                    >
                      MENS HEALTH - NEW PATIENT QUESTIONNAIRE
                    </router-link>
                  <br><br>
                    <router-link
                      to="/forms/new-patient-musculoskeletal"
                      class="btn mb-3 mb-sm-0 btn-dark w-75 btn-black"
                    >
                      MUSCULOSKELETAL / INJURY QUESTIONNAIRE
                    </router-link>
                  </div>
                </div>
                <p class="mt-4">This step is essential for optimizing our session. It allows us to focus on education and achieving results rather than spending valuable time on history recording. Your cooperation in filling out this form is greatly appreciated and will contribute to the efficiency and effectiveness of our time together.</p>
                <li class="mt-4">If you have any recent, relevant pathology results, please send a complete copy to the clinic's WhatsApp number <a href="tel:+6281337209504" class="btn-outline-secondary text-black-50 text-underline">+62 813 3720 9504</a> so that Michelle can review them before your appointment.</li>
              </ol>
            </section>
            <!-- Follow-up Consultation Section -->
            <section>
              <h2 class="display-3 text-white mb-4">Follow-up consultation (50-60 minutes)</h2>
              <p class="mb-4">During our follow-up consultation, we'll review your progress since our last session. I'll conduct another acupuncture treatment to continue supporting your body's natural healing processes. We'll also revisit the herbal medicine and supplementation regimen prescribed earlier, ensuring it aligns with your current needs.</p>
              <p class="mb-4">Furthermore, we'll discuss how you're integrating any recommended lifestyle and dietary changes. Based on your feedback and any evolving health concerns, we'll modify these aspects as needed, deepening our focus on personalized care.</p>
            </section>
          </div>
        </div>
      </section>
            <!-- Testimonials -->
    <LazyHydrate when-visible>
      <ConsultTestimonials></ConsultTestimonials>
    </LazyHydrate>
    <!-- End testimonials -->
    </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'consults',
  components: {
    LazyHydrate,
    ConsultTestimonials: () => import('./components/ConsultTestimonials.vue')
  },
  metaInfo: {
    title: 'Consults'
    // override the parent template and just use the above title only
    // titleTemplate: null
  }
}
</script>
