<template>
<card shadow class="card-profile mt--300" no-body>
                    <div class="px-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="#">
                                        <img v-lazy="$options.filters.imgsrc('img/michelle-hansen.jpg')" alt="PMS Hormonal Imbalance" class="rounded-circle">
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                                <div class="card-profile-actions py-4 mt-lg-0 text-center">
                                  <a target="_blank" rel="noopener" href="http://instagram.com/flowingwomban"
                                    class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg" data-toggle="tooltip"
                                    data-original-title="Follow us">
                                      <i class="fa fa-instagram"></i>
                                  </a>
                                    <!-- <base-button type="info" size="sm" class="mr-4">Connect</base-button>
                                    <base-button type="default" size="sm" class="float-right">Message</base-button> -->
                                </div>
                            </div>
                            <div class="col-lg-4 order-lg-1">
                                <div class="card-profile-stats d-flex justify-content-center">
                                    <div>
                                        <span class="heading">25+ Years</span>
                                        <span class="description text-dark">Clinical Practice</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-5">
                            <h3>Michelle Hansen
                                <!-- <span class="font-weight-light">, 27</span> -->
                            </h3>
                            <div class="h5 text-dark">The Author</div>
                            <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>Natural Therapist - Acupuncturist - Chinese Herbalist - Certified Yoga Instructor</div>
                            <!-- <div><i class="ni education_hat mr-2"></i>University of Computer Science</div> -->
                        </div>
                        <div class="mt-5 py-5 border-top text-center">
                            <div class="row justify-content-center">
                                <div class="col-lg-9">
                                    <p>Michelle Hansen is a complementary/holistic health care practitioner with over 25 years of experience in the field. She wears many hats – natural therapist, acupuncturist, certified yoga instructor and mother of three.</p>
                                    <p>Michelle spent her teen years obsessed with sports, which led to a keen interest in exercise therapy, nutrition, alternative medicine and supplementation. After high school, she started a science degree with the idea of entering the medical field in some capacity, but was quickly disillusioned with the "tools" of modern medicine and its lack of focus on preventing illness and treating the whole person. So,  Michelle changed tact and studied Naturopathy, becoming enthralled in Traditional Chinese Medicine and Taoism along the way.</p>
                                    <p>Michelle completed her acupuncture degree in 1991 and has been practicing ever since. She’s travelled Asia extensively, studying Buddhism and many forms of traditional healing. She also has an Asian Studies degree from Griffith University with a double major in Mandarin.</p>
                                    <p>After many years of clinical experience in Australia, Michelle relocated to Bali, Indonesia where she’s as passionate as ever about helping people achieve their wellness goals. <br>
                                    Her main clinical interest is in helping women to achieve happier, healthier periods .</p>
                                    <!-- <a href="#">Show more</a> -->
                                    <blockquote class="text-center" data-aos="zoom-in">
                                      <p>
                                        The challenges of modernity, environmental toxins, poor nutrition and medications are just some of the reasons women are facing challenges to our health like never before: It’s no wonder that women are suffering through menopause, hormonal and metabolic disorders such as PCOS, endometriosis and mood disorders are the “norm” and fertility is a issue . Clearly our current medical approach is limited and we need an alternative approach. <br>
                                        Traditional Chinese Medicine is perfect for this niche.
                                      </p>
                                    <cite>— Michelle Hansen.</cite>
                                    <a
                                        href="/"
                                        class="btn btn-dark mt-4"
                                        >SIGN UP TODAY</a
                                      >
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
</template>
<script>
export default {}
</script>
<style scoped>
blockquote {
    position: relative;
    text-align: left;
    padding: 1.2em 0 2em 38px;
    border: none;
    margin: 20px auto 20px;
    max-width: 800px;
    width:100%;
    display: block;
}

blockquote:after {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    color: #66cc66;
    top: 0;
    background: -moz-linear-gradient(top,#66cc66 0%,#66cc66 60%,rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,#66cc66 0%,#66cc66 60%,rgba(255,255,255,0) 100%);
    /* background: linear-gradient(to bottom,#66cc66 0%,#66cc66 60%,rgba(255,255,255,0) 100%); */
}

blockquote:before {
    content:"\f10d";
    font-family: "fontawesome";
    font-size: 20px;
    display: block;
    margin-bottom: 0.8em;
    font-weight: 400;
    color: #66cc66;
}

blockquote > cite,
blockquote > p > cite {
    display: block;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 700;
    font-style: normal;
    margin-top: 1.1em;
    letter-spacing: 0;
    font-style:italic;
}

</style>
