<template>
    <!-- <footer class="footer has-cards"> -->
    <footer class="footer">
        <!-- <div class="container container-lg">
            <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                    <div class="card card-lift--hover shadow border-0">
                        <router-link to="/landing" title="Landing Page">
                            <img v-lazy="'img/theme/landing.jpg'" class="card-img">
                        </router-link>
                    </div>
                </div>
                <div class="col-md-6 mb-5 mb-lg-0">
                    <div class="card card-lift--hover shadow border-0">
                        <router-link to="/profile" title="Profile Page">
                            <img v-lazy="'img/theme/profile.jpg'" class="card-img">
                        </router-link>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="container">
            <div class="row row-grid justify-content-center align-items-center my-sm">
                <div class="col-lg-12 text-center">
                    <h3 class="text-primary font-weight-light mb-2">ACUPUNCTURE AND WELLBEING BALI</h3>
                    <p class="m-2 font-weight-light">Jalan Pantai Batu Bolong 117X</p>
                    <p class="m-2 font-weight-light">Canggu, Bali</p>
                    <p class="m-2 font-weight-light">Indonesia</p>
                    <p class="m-2 font-weight-light">
                      <a href="mailto:info@naturalmedicineportal.com" target="_blank" class="text-dark text-underline">
                        info@naturalmedicineportal.com
                      </a>
                    </p>
                    <!-- <p class="m-2 font-weight-light">+62 8133 7209 504</p> -->
                    <p class="m-2 font-weight-light">+62 8214 7819 086</p>
                </div>
                <div class="col-lg-6 text-center btn-wrapper">
                    <a target="_blank" rel="noopener" href="http://instagram.com/flowingwomban"
                       class="btn btn-neutral btn-icon-only btn-instagram btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Follow us">
                        <i class="fa fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener" href="https://www.facebook.com/flowingwomban"
                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Like us">
                        <i class="fa fa-facebook-square"></i>
                    </a>

                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-12 text-center">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="http://www.mnmalik.com" target="_blank" rel="noopener">Mudasir Malik</a>
                    </div>
                </div>
                <div class="col-md-12">
                    <ul class="nav nav-footer justify-content-center">
                        <!-- <li class="nav-item">
                            <a href="https://www.creative-tim.com" class="nav-link" target="_blank" rel="noopener">Creative Tim</a>
                        </li> -->

                        <li class="nav-item">
                          <router-link to="/blog" class="nav-link">Blog</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link to="/contact" class="nav-link">Contact Us</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link to="/about" class="nav-link">About Us</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style>
</style>
